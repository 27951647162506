const STEPS = {
    GENERAL: 'general',
    LISTING: 'listing',
    PROSPECTS: 'prospects',
    SCHEDULE: 'schedule',
    SHOWINGS: 'showings',
    PUBLISH_LISTING: 'publish listing',
};

const STEPS_ORIGINS = {
    NEXT: 'next',
    MANUAL: 'manual',
};

export { STEPS, STEPS_ORIGINS };
