<template>
  <ElDialog
    id="agent-network-not-available-dialog"
    :center="true"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="uiFlags.isVisible"
    :fullscreen="$viewport.lt.md"
  >
    <div class="row justify-content-center gutters-2">
      <template>
        <div class="col-12">
          {{ messageRef }}
        </div>
      </template>
    </div>
    <div
      slot="footer"
      class="row gutter-1 justify-content-center"
    >
      <div class="col col-md-4">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          @click="goBack()"
        >
          Ok
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';

export default {
  name: 'AgentNetworkNotAvailableDialog',
  components: {},
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
    });
    const messageRef = ref('Our agent network is not yet available at this location.'
      + 'With our Standard subscription, you can still use Showdigs to'
      + 'manage tours at this property using your in-house staff.');

    return {
      uiFlags,
      open,
      goBack,
      messageRef,
    };

    async function open(customMessage = null) {
      if (customMessage) {
        messageRef.value = customMessage;
      }
      uiFlags.isVisible = true;
    }

    function goBack() {
      context.root.$router.go(-1);
    }
  },
};
</script>
