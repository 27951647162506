import momentUtil from '@/utils/MomentUtil';
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';
import { BusinessSourceTypes } from '@/constants/BusinessSource';

export {
  resolveApplicationUrl,
  availabilityDateFormat,
  resolveHideListingSection,
  resolveAllowGeneratingAd,
};

function resolveApplicationUrl(prefill, business) {
  if (business.value.sync_application_url) {
    return prefill.application_url ?? prefill.application_form_url ?? prefill.listing_url;
  }
  return null;
}

function availabilityDateFormat(availabilityDate) {
  if (availabilityDate) {
    const availabilityDateInUTC = momentUtil(availabilityDate, 'UTC');
    const availabilityDateInPropertyTimezone = availabilityDateInUTC.toServerFormat().replace(/-/g, '/');
    return new Date(availabilityDateInPropertyTimezone);
  }
  return null;
}

function resolveHideListingSection(context) {
  const integrationType = getBusinessSourceType();
  const isIntegrationEnabled = context.root.$store.getters['BusinessSource/isIntegrationEnabled'];
  const isLegacyIntegrationEnabled = context.root.$store.getters['BusinessSource/isLegacyIntegrationEnabled'];
  const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
  if (isLegacyIntegrationEnabled) {
    return false;
  }
  if (BusinessSourceTypes.APPFOLIO === integrationType) {
    return true;
  }
  return isIntegrationEnabled && !isSyndicated;
}

function resolveAllowGeneratingAd(payload) {
  return payload.bedrooms && payload.rent && payload.size && payload.bathrooms;
}
