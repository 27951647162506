<template>
  <div
    id="showing-profile-activation-step"
    class="row"
  >
    <div class="col-md-12">
      <div
        v-if="withStartDateField"
        id="showing-start-date"
        class="row justify-content-between mb-2"
      >
        <div class="col-12 col-md-6 pt-0">
          <div class="font-21 mb-2">
            Date to start showings
          </div>
          <SdFloatLabel>
            <ElDatePicker
              ref="datePicker"
              v-model="pickerDateRef"
              prefix-icon="sdicon-calendar"
              type="date"
              :picker-options="{ disabledDate }"
              :clearable="false"
              align="center"
              :format="showTodayStringInDatePickerRef ? '[Today]' : 'dd MMMM'"
              placeholder="Pick a date"
              @change="dayChanged"
            />
          </SdFloatLabel>
        </div>
      </div>
      <template v-if="withTourLimit && property.market_id">
        <div
          v-if="marketShowingAgentsAvailableRef"
          class="tour-limit justify-content-between mb-2 mt-4"
        >
          <ShowingProfileToursBudget
            :current-budget="maxBudgetedTours"
            @update-budget="updateBudget"
          />
        </div>
      </template>
      <div>
        <slot name="access-instructions" />
      </div>
      <div
        v-if="isSelfShowing && !isPublishListing"
        class="mt-3 border border-gray-light p-4"
      >
        <div class="row">
          <div class="col-12">
            <h3 class="mb-3 font-21 d-flex align-items-center">
              <i class="sdicon-fingerprint text-gray-dark mr-2" />
              <span>ILS Monitoring</span>
              <SdStatusBadge
                class="ml-auto my-0"
                :type="business && business.self_showing_ils_monitoring_disabled_at ? 'disabled' : 'enabled'"
              />
            </h3>
            <div class="font-15">
              To help prevent scams, we actively monitor and remove fraudulent ads from Craigslist and Facebook for all self showings.
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 border border-gray-light p-4">
        <div class="row">
          <div class="col-12">
            <h3 class="mb-3 font-21 d-flex justify-content-between align-items-center">
              <span><i class="sdicon-fingerprint text-gray-dark mr-2" />AI Identity Verification</span>
              <ElSwitch
                v-model="hasIdentityVerification"
                :width="44"
                class="d-block align-self-center"
                @change="updateIdentityVerification"
              />
            </h3>
            <div class="font-15">
              Protect yourself from scammers by using AI-powered facial recognition and ID verification to
              verify prospects before they can schedule a tour.
            </div>
          </div>
        </div>
      </div>
      <ShowingProfileActivationNotes
        v-if="property.market_id && !isSelfShowing && withActivationNotes"
        :market-id="property.market_id"
        :showing-profile="showingProfile"
        class="mt-3"
      />
      <div class="conditions mt-3">
        <div
          v-if="!showingProfile.is_vacant"
          class="row condition"
          :class="{ error: uiFlags.isInvalidConditions && conditions.tenantAgreement === false }"
        >
          <ElCheckbox
            v-model="conditions.tenantAgreement"
            label="The current tenants have been notified and agreed for tours at the property during the showing windows defined previously."
            class="col-auto"
          />
        </div>
        <div
          v-if="marketShowingAgentsAvailableRef && !isSelfShowing && !isPublishListing"
          class="row condition"
          :class="{ 'error': uiFlags.isInvalidConditions && conditions.accessPermission === false }"
        >
          <ElCheckbox
            v-model="conditions.accessPermission"
            label="By submitting this form, I give Showdigs Agents permission to access the property to conduct showings.
              I am also authorizing Showdigs to charge my credit card for this service."
            class="col-auto"
          />
        </div>
      </div>

      <ElButton
        type="primary"
        class="activate-btn mt-3 w-100"
        :disabled="!uiFlags.isValidConditions || uiFlags.isActivating"
        :loading="uiFlags.isActivating"
        @click="activate"
      >
        {{ isPublishListing ? 'Publish Listing' : 'Enable Showings' }}
        {{ withMoveInInspection ? 'and request a condition report' : '' }}
      </ElButton>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import momentUtil from '@/utils/MomentUtil';
import get from 'lodash.get';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import ShowingProfileActivationNotes from '@/components/showing-profile/ShowingProfileActivationNotes';
import { percentOff } from '@/utils/NumberUtil';
import { IdentityVerificationTypes } from '@/constants/Business';
import SdStatusBadge from '@/components/common/SdStatusBadge';
import { getPricesValueByKey } from '@/utils/PriceUtil';
import Price from '@/constants/Price';
import ShowingProfileToursBudget, { defaultBudget } from '@/components/showing-profile/ShowingProfileToursBudget';

export default {
  name: 'ShowingProfileEnable',
  components: {
    ShowingProfileActivationNotes,
    ShowingProfileToursBudget,
    SdStatusBadge,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    showingProfile: {
      type: Object,
      required: true,
    },
    isSelfShowing: {
      type: Boolean,
      default: false,
    },
    formRef: {
      type: Object,
      required: true,
    },
    isPublishListing: {
      type: Boolean,
      default: false,
    },
    withStartDateField: {
      type: Boolean,
      default: true,
    },
    withTourLimit: {
      type: Boolean,
      default: true,
    },
    withActivationNotes: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const { $store } = context.root;
    const env = process.env.VUE_APP_ENV;
    const business = context.root.$store.getters['Auth/business'];
    const fullPrices = {
      oneBr: getPricesValueByKey(Price.MOVE_IN_OUT_SMALL, props.property.market_id),
      fourBrPlus: getPricesValueByKey(Price.MOVE_IN_OUT_LARGE, props.property.market_id),
    };
    const discountedPrices = {
      oneBr: percentOff(getPricesValueByKey(Price.MOVE_IN_OUT_SMALL, props.property.market_id), 10),
      twoThreeBr: percentOff(getPricesValueByKey(Price.MOVE_IN_OUT_MEDIUM, props.property.market_id), 10),
      fourBrPlus: percentOff(getPricesValueByKey(Price.MOVE_IN_OUT_LARGE, props.property.market_id), 10),
    };
    const uiFlags = reactive({
      isActivating: false,
      isValidConditions: computed(computeIsValidConditions),
      isMissingSource: false,
    });
    const conditions = reactive({
      accessPermission: false,
      tenantAgreement: false,
    });
    const timezone = props.property.timezone;
    const now = momentUtil(null, timezone);
    const pickerDateRef = ref(now);
    const withMoveInInspection = ref(props.showingProfile.with_move_in_inspection && !props.isSelfShowing);
    const marketShowingAgentsAvailableRef = ref(false);
    const marketInspectionAgentsAvailableRef = ref(false);
    const hasIdentityVerification = ref(props.showingProfile.has_identity_verification);
    const startOfDay = momentUtil(now, timezone)
      .startOf('day')
      .toDate();
    const showTodayStringInDatePickerRef = ref(true);

    if (withMoveInInspection.value && !props.showingProfile.is_vacant) {
      withMoveInInspection.value = false;
      updateWithMoveInInspection(withMoveInInspection.value);
    }

    const maxBudgetedTours = computed(() => props.showingProfile.max_budgeted_tours || defaultBudget);

    init();

    return {
      updateBudget,
      activate,
      dayChanged,
      disabledDate,
      updateIdentityVerification,
      env,
      business,
      showTodayStringInDatePickerRef,
      conditions,
      uiFlags,
      pickerDateRef,
      withMoveInInspection,
      discountedPrices,
      fullPrices,
      marketShowingAgentsAvailableRef,
      marketInspectionAgentsAvailableRef,
      maxBudgetedTours,
      hasIdentityVerification,
    };

    async function init() {
      marketShowingAgentsAvailableRef.value = get(props.property.market, 'showing_agents_available');
      marketInspectionAgentsAvailableRef.value = get(props.property.market, 'inspection_agents_available');
    }

    async function activate() {
      if (!uiFlags.isValidConditions) {
        return;
      }
      try {
        props.formRef.validate(async (valid) => {
          if (valid) {
            uiFlags.isActivating = true;
            await activateShowing();
            }
        });
      } catch (error) {
        if (!(error instanceof Error)) {
          // Validator failed
        }
      }
    }

    async function activateShowing() {
      const payload = { ...props.showingProfile };
      if (!props.isSelfShowing) {
        payload.showing_windows = payload.showing_windows.map((showingWindow) => {
          if (showingWindow.assignee_id === 'showdigs') {
            return {
              ...showingWindow,
              assignee_id: null,
            };
          }
          return { ...showingWindow };
        });
      }
      const availabilityDate = get(payload, 'screening_criteria.availability_date');
      if (availabilityDate) {
        const timezone = props.property.timezone;
        const availabilityDateInPropertyTimezone = momentUtil.changeTz(availabilityDate, timezone);
        const availabilityDateInUTC = momentUtil.changeTz(availabilityDateInPropertyTimezone, 'UTC')
          .toServerFormat();
        payload.screening_criteria.availability_date = availabilityDateInUTC;
      }

      try {
        const showingProfile = await context.root.$store.dispatch('ShowingProfile/enable', payload);
        const address = showingProfile.unit.property.short_address;
        const defaultMessage = `Showing enabled. Showdigs can now show the property ${address}.`;
        const publishListingMessage = `Listing enabled. Inquiries can now be received for the property ${address}.`;
        const message = props.isPublishListing ? publishListingMessage : defaultMessage;
        showSuccessNotify(context, message);
        uiFlags.isActivating = false;
        context.emit('activate-showing', { showingProfile });
      } catch (error) {
        uiFlags.isActivating = false;
        payload.screening_criteria.availability_date = availabilityDate;
        showErrorNotify(context, error.message);
      }
    }

    function dayChanged(day) {
      const dayInMoment = momentUtil(day, timezone);
      const todaySelected = dayInMoment.isSame(momentUtil(null, timezone), 'day');
      context.emit('update-showing-profile-payload', {
        paused_until: todaySelected ? null : momentUtil(day, null)
          .toServerFormat(),
        pause_reason: todaySelected ? null : 'pre_leasing_period',
      });
      showTodayStringInDatePickerRef.value = todaySelected;
    }

    function disabledDate(date) {
      return date < startOfDay;
    }

    function updateBudget(budget) {
      context.emit('update-showing-profile-payload', { max_budgeted_tours: budget });
    }

    function updateWithMoveInInspection(withMoveInInspection) {
      context.emit('update-showing-profile-payload', { with_move_in_inspection: withMoveInInspection });
    }

    function updateIdentityVerification(identityVerification) {
      context.emit('update-showing-profile-payload', { has_identity_verification: identityVerification });
    }

    function computeIsValidConditions() {
      if (!props.showingProfile.is_vacant && !conditions.tenantAgreement) {
        return false;
      }

      if (!props.isPublishListing && !props.isSelfShowing && marketShowingAgentsAvailableRef.value && !conditions.accessPermission) {
        return false;
      }

      return true;
    }
  },
};
</script>

<style lang="scss">
#showing-profile-activation-step {
  .el-divider--horizontal {
    margin: 2.5rem 0;
  }

  #showing-start-date {

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input__inner {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .el-input__prefix {
      display: none;
    }
  }

  .cc-form {
    .StripeElement {
      border-radius: $app-border-radius;
      background-color: gray-color('lighter');
      padding: 1.25rem 1rem;
    }
  }

  #identity-verification {
    border: 1px solid gray-color('light');
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .condition {
    margin-top: -1rem;

    &.error {
      .el-checkbox__label {
        color: theme-color('danger');
      }

      .el-checkbox__inner {
        border: 1px solid theme-color('danger');
      }
    }
  }

  .condition {
    .is-checked .el-checkbox__inner {
      background-color: theme-color(primary);
    }

    .el-checkbox__label {
      white-space: normal;
    }

    .el-checkbox__input {
      vertical-align: top;
    }

    .el-checkbox__inner {
      width: 1.5rem;
      height: 1.5rem;

      &:after {
        width: 6px;
        height: 12px;
        border-color: $white;
        top: 2px;
        left: 7px;
      }
    }
  }

  .activate-btn {
    white-space: normal;
  }
}
</style>
