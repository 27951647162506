<template>
  <ElAlert
    type="info"
    show-icon
    class="activation-notes"
    :closable="false"
  >
    <ElSpinner
      v-if="includedToursLeft === null"
      color="primary"
    />
    <ul
      v-else
      class="font-15"
    >
      <template v-if="showingProfile.is_open_house">
        <li>
          For open houses hosted by Showdigs Agent, the cost is ${{ openHouseHourlyBusinessCharge }} for 1-hour
          or ${{ openHouse2HoursBusinessCharge }} for 2-hours
        </li>
      </template>
      <template v-else-if="isStandardTypePlan">
        <li>You will be charged ${{ getPricesValueByKey(Price.AGENT_TOUR, marketId) }} for any tours led by a Showdigs agent</li>
        <li v-if="getSettingValueByKey('pm_no-show_tour_charge', marketId) === 0">
          No-show protection is active - you will not be charged for no-shows
        </li>
      </template>
      <template v-else>
        <template v-if="plan.included_tours > 0">
          <li
            v-if="includedToursLeft <= 0"
            class="font-weight-strong"
          >
            You've used all {{ plan.included_tours }} monthly tour credits included with your
            <span class="capitalize">{{ plan.name }}</span> subscription
          </li>
          <li
            v-else
            class="font-weight-strong"
          >
            You have {{ includedToursLeft }} tour credits left (out of {{ plan.included_tours }})
            as part of your {{ plan.name }} subscription
          </li>
        </template>
        <li v-if="plan.no_show_tour_price > 0">
          No-show protection inactive - you will be charged for no-shows.<br>
          Our Standard plan include no-show protection.
          <RouterLink
            :to="{ name: 'app.billing', query: {modify:true}}"
            target="_blank"
          >
            Learn more
          </RouterLink>
        </li>
        <li>
          You will be charged ${{ plan.additional_completed_tour_price }} for any tours led by a Showdigs agent
        </li>
        <li v-if="plan.no_show_tour_price === 0">
          No-show protection is active - you will not be charged for no-shows
        </li>
      </template>
    </ul>
  </ElAlert>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import momentUtil from '@/utils/MomentUtil';
import { isStandardTypePlan } from '@/utils/PlanUtil';
import Plan from '@/constants/Plan';
import { getSettingValueByKey } from '@/utils/SettingUtil';
import { getPricesValueByKey } from '@/utils/PriceUtil';
import Price from '@/constants/Price';

export default {
  name: 'ShowingProfileActivationNotes',
  props: {
    marketId: {
      required: true,
      type: Number,
    },
    showingProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const plan = context.root.$store.getters['Auth/plan'];
    const subscription = ref(null);
    const includedToursLeft = computed(computeIncludedToursLeft);
    const openHouseHourlyBusinessCharge = getPricesValueByKey(Price.ONE_HOUR_OPEN_HOUSE, props.marketId);
    const openHouse2HoursBusinessCharge = getPricesValueByKey(Price.TWO_HOURS_OPEN_HOUSE, props.marketId);

    init();

    return {
      Plan,
      Price,
      plan,
      includedToursLeft,
      isStandardTypePlan: isStandardTypePlan(plan),
      getSettingValueByKey,
      getPricesValueByKey,
      openHouseHourlyBusinessCharge,
      openHouse2HoursBusinessCharge,
    };

    async function init() {
      subscription.value = await context.root.$store.dispatch('Subscription/get');
    }

    function computeIncludedToursLeft() {
      if (subscription.value === null) {
        return null;
      }
      const count = subscription.value.paid_tours_count;
      return plan.included_tours - count;
    }
  },
};
</script>

<style lang="scss" scoped>
.activation-notes {
  background-color: rgba(theme-color('primary'), 0.1);
  padding: 1rem;
  ul{
    padding: 0 0 0 1rem;
    margin: -0.25rem 0 0 0;
    li{
      margin: 0.5rem 0;
    }
  }
}
</style>
