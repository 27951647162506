<template>
  <ElAlert
    v-if="visible"
    type="info"
    show-icon
    :closable="false"
    class="mt-3"
  >
    <p class="mt-2 mb-0 pl-3">
      {{ integrationType }} integration is enabled, so all listing-related information is
      managed in {{ integrationType }} and will automatically sync into Showdigs.
    </p>
  </ElAlert>
</template>

<script>
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';

export default {
  name: 'IntegrationEnabledListingsAlert',
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const integrationType = capitalizeFirstLatter(getBusinessSourceType());
    return {
      integrationType,
    };
  },
};
</script>
